<template>
	<div>
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
    	<div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Products</h1>
        <router-link :to="{ name: 'dashAddProduct' }">
          <button class="btn"><i class="fad fa-plus fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <transition name="fadeStop">
        <Loader v-if="!products || products.length < 1" />
      </transition>
      <div class="list__item" v-for="(item, index) in products" :key="index">
        <div class="list__item--body--sm">
          <img :src="item.pics[0]" alt="" /> 
          <div class="inline-block">
            <h3 class="mb-0">{{item.manufacturer}} {{item.part}} <span class="light">// ${{item.contractPrice}}<span v-if="!item.visible"> // <i class="fad fa-eye-slash"></i></span></span></h3>
            <p v-html="item.commodityDescription"></p>
            <button class="btn__chip">Size: {{item.size}}</button><button class="btn__chip">N95 ({{item.style}})</button><button class="btn__chip">Color: {{item.color}}</button><button class="btn__chip">Strap: {{item.strap}}</button>
          </div>
        </div>
        <div class="list__button">
          <router-link :to="`/dashboard/products/` + item.id">
            <button class="btn btn__outlined ma-2">Edit</button>
          </router-link>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'dashProducts',
  data() {
    return {
  	}
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'products']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "Products", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  components: {
    Loader,
    Breadcrumbs,
  },
}
</script>